import React from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  };

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    this.makeShot(0.2, {
      spread: 60,
    });

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  handlerFire = () => {
    this.fire();
    document.getElementById("myBtn").innerHTML = "Bingo 🎉";
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  render() {
    return (
      <div className="flex justify-center bg-primary content-center">
        <div className="pt-24 h-screen text-left sm:text-center">
          <h1 className="text-gray-200 text-3xl pl-5 ">Hey there 👋,</h1>
          <p className="text-slate-100 pt-5 pl-5 text-lg mb-10 sm:mb-24 font-mono">
            You might be wondering what this{" "}
            <span className="bold text-2xl text-pink-500 ">urqt</span> is all
            about; simply read each letter to find out. If you get it, please
            click the button below.
          </p>

          <div className="pl-5">
            <button
              onClick={this.handlerFire}
              className="inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-slate-200 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400"
            >
              <span
                id="myBtn"
                className="text-lg px-5 py-2.5 transition-all ease-in duration-75 bg-primary rounded-md group-hover:bg-opacity-0"
              >
                Click Me
              </span>
            </button>
          </div>
          <ReactCanvasConfetti
            refConfetti={this.getInstance}
            style={canvasStyles}
          />
        </div>
      </div>
    );
  }
}
